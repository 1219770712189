.links-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .link-component {
    display: block;
    background: transparent;
    border: 0;
    margin: 16px 0;
    padding: 0;
    text-decoration: none;
  }

  svg {
    width: 24px;
    height: 24px;
    margin: 0 16px;
    cursor: pointer;
  }
}
