@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.shop-card-zone {
  position: relative;
  width: 100%;
  height: 100%;

  &__tags {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__tag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    z-index: 0;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 12px;
      left: 12px;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
    }
  }

  &__image {
    display: block;
    @include imageObjectFit();
  }

  &__icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    @include size(32px);
    padding: 12px;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 50px;
    transition: background-color .25s ease;

    //media
    @include MQ(s) {
      bottom: 8px;
      right: 8px;
    }
    //media

  }
  &__icon-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}
