// utils
@import "../../../assets/scss/utils/variables";
@import "../../../assets/scss/mixins";

.form-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  &__avatar {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    border-radius: 50%;
  }

  &__avatar-default {
    border-radius: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }

  &__message {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    word-break: break-word;
  }

  &__button {
    display: block;
    margin-top: auto;
  }

  &__button {
    display: block;
    width: 100%;
    margin-top: auto;
    padding: 16px;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;

    @include phone {
      width: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__button_text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    &-gradient {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
    }
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &-top {
    margin: 24px;
    flex-grow: initial;
  }
}

.inside-accordion {
  margin-bottom: 0 !important;
  padding-bottom: 0;
  border: none !important;
  box-shadow: none !important;
}

.order-top {
  order: -1;
  margin-bottom: 15%;
}
