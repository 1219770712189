@import "../mixins";

.swipe-gallery-item {
  position: relative;
  width: 588px;
  height: 588px;
  backdrop-filter: blur(10px);
  overflow: hidden;

  @include phone {
    height: calc(100vw - 32px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

