// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/mixins";

.featured-bio-site-banner {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 192px;
  padding-top: 24px;
  background: #FFFFFF;
  border-radius: 0px;
  z-index: 1;

  @include phone {
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0px 0px;
  }

  .inner-container {
    position: static;
  }

  &__logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    top: -24px;
    right: 0;
    left: 0;
    background: #FFFFFF;
    border-radius: 50%;
  }

  &__title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .create-bio-site-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 229px;
    height: 48px;
    margin: 16px auto 0;
    background: #000000;
    border: 2px solid #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    z-index: 1;

    &_disabled {
      cursor: default;
    }
  }

  .create-bio-site-button__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .create-bio-site-button__text {
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
  }

  .create-bio-site-button__icon-arrow {
    position: relative;
    height: 17px;
    top: 1px;
    left: 3px;
  }

}
