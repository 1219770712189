@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.actions {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  overflow: hidden;
  transform: translateZ(0);

  &__divider {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 1px;
    height: calc(100% - 24px);
    opacity: .5;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.popup {

  &__container {
    max-height: 608px;

    //media
    @include MQ(s) {
      max-height: initial;
    }
    //media

  }
}
