@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.body {
  position: relative;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: 100%;

  &__container {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0 56px;

    //media
    @include MQ(s) {
      padding: 0 32px;
    }
    //media

  }
}
.diet {
  margin-top: 66px;
  padding-bottom: 40px;

  &__item {
    margin-top: 16px;
  }
}
