// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/UI/loader";

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-color: #fff;
  z-index: 10;

  &__logo {
    position: relative;
    transform-origin: center;

    img {
      max-width: initial;
      animation: loader-scale 20s linear alternate infinite;

      @keyframes loader-scale {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(2);
        }
      }
    }
  }

  &__spinner {
    position: relative;
    top: auto;
    right: auto;
  }
  &__spinner-body {
    border-bottom: 2px solid transparent;
    border-left: 2px solid currentColor;
    border-radius: 50%;
    border-right: 2px solid transparent;
    border-top: 2px solid currentColor;
  }

  &_spinner {
    background-color: transparent;
  }
}
