@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.body {
  position: relative;
  margin-top: 8px;
  padding: 0 24px;

  //media
  @include MQ(s) {
    padding: 0 32px;
  }
  //media

  &__image {
    display: block;
    border-radius: 8px;
    max-width: 215px;
    margin: 0 auto;
    pointer-events: none;

    //media
    @include MQ(xxs) {
      max-width: 100%;
    }
    @media (max-height: 550px) and (orientation: landscape) {
      max-width: 115px;
    }
    //media

  }
  &__title {
    margin-top: 16px;
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
  }
  &__list {
    margin-top: 24px;
    padding: 0 0 21px;

    //media
    @include MQ(s) {
      margin-top: 40px;
    }
    @media (max-height: 550px) and (orientation: landscape) {
      margin-top: 24px;
    }
    //media

  }
}
