@import "../mixins";

.tweet-component {
  position: relative;
  max-width: 550px;
  width: 100%;
  margin: 0 !important;

  & > div > div {
    margin: 0 !important;
  }
  iframe {
    width: 100% !important;
  }
}

