// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/mixins";

.form-component {
  padding: 24px;

  ul {
    overflow-x: hidden;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    word-break: break-word;
  }

  &__description {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    word-break: break-word;
  }

  &__phone {
    position: relative;
    width: 100% !important;
    height: 48px !important;
    max-height: 48px;
    margin-bottom: 20px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    background: $grey_bg;
    box-shadow: inset 0 0 0 1px $stroke;
    border-radius: 4px;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border: none !important;
    -webkit-appearance: none;
    transition: box-shadow 0.25s ease;

    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all .25s ease;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $green;
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $green;
      text-overflow: unset;

      &::-webkit-input-placeholder {
        opacity: 0;
        //transition-delay: .35s;
      }

    }
    &:disabled {
      opacity: .4;
      cursor: default;
    }

    &-dropdown {
      top: 42px;
      background: #FFFFFF !important;
      color: #000000 !important;
      border: 1px solid #000000 !important;
      border-radius: 4px !important;
      transition: 0.25s ease-in;
      @include desktopLarge {
        width: 540px !important;
      }

      @include desktop {
        width: 540px !important;
      }
    }

    &_error {
      box-shadow: inset 0 0 0 1px $red;

      &:hover {
        box-shadow: inset 0 0 0 1px $red;
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $red;
      }
    }
  }

  &__phone-btn {
    box-shadow: inset 0 0 0 1px $stroke;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none !important;
    -webkit-appearance: none;
    transition: box-shadow 0.25s ease;

    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all .25s ease;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $green;
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $green;
      text-overflow: unset;

      &::-webkit-input-placeholder {
        opacity: 0;
        //transition-delay: .35s;
      }

    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    text-align: center;
    cursor: pointer;
  }

  input, textarea {
    opacity: 0.5;
    transition: opacity 0.25s ease-in !important;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s !important;
    }
  }
}

.auto-height {
  height: auto !important;
}

.form-component__button {
  display: flex;
  width: 100%;
  margin-top: 0;
  padding: 16px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
}

.form-component__button_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  word-break: break-word;
}

.form-component__button {

  svg {
    animation: spinning 3s linear infinite;
  }

  @include phone {
    width: 100%;
    margin-top: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.input-phone-wrapper {
  position: relative;
}

.invalid-number-message {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
}

.form-component__button_text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.input__field_textarea-dynamic-height {
  min-height: initial !important;
  max-height: initial !important;
  transition: height 0.2s ease-in !important;
  white-space: break-spaces !important;
}

.opacity-off,  {
  opacity: 1;
  transition: opacity 0.25s ease-in !important;

  * {
    opacity: 1 !important;
    transition: opacity 0.25s ease-in !important;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inside-accordion {
  margin-bottom: 0 !important;

  .form-component {
    padding-bottom: 0;
    border: none !important;
    box-shadow: none !important;
  }
}
