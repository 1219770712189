@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes skeleton-animation {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
