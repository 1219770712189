@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.header {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  background: #fff;
  min-height: 56px;
  z-index: 1;

  &__wrapper {
    position: relative;
    align-items: center;
    padding: 16px;
  }

  &__image {
    display: block;
    width: 40px;
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 20px;
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    @include size(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
  }
  &__close-icon {
    display: block;
  }
}
