@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.menu {
  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }
}
