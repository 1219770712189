@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/mixins";

.item {
  position: relative;
  width: 160px;
  margin-bottom: 24px;
  cursor: pointer;
  text-decoration: none;
  $this: &;

  @include phone {
    width: 148px;
  }

  @include largePhone {
    width: 160px;
  }

  @media (max-width: 343px) {
    width: 128px;
  }

  @media (max-width: 320px) {
    width: 115px;
  }

  picture {
    display: block;
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
    height: 160px;
    pointer-events: none;
    object-fit: cover;
    border-radius: 16px;

    @include phone {
      height: 148px;
    }

    @include largePhone {
      height: 160px;
    }

    @include MQ(xxs) {
      max-width: 100%;
    }

    @media (max-height: 550px) and (orientation: landscape) {
      max-width: 115px;
    }

    @media (max-width: 343px) {
      height: 128px;
    }

    @media (max-width: 320px) {
      height: 115px;
    }

  }

  &__title {
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 384px;
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0C1329;

    @include phone {
      max-width: 311px;
    }

    @include largePhone {
      max-width: 326px;
    }

    @media (max-width: 343px) {
      max-width: 311px;
    }
  }

  &__price {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #0C1329;
  }

  .small {
    @media (max-width: 343px) {
      font-size: 12px;
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
    @include size(32px);
    padding: 8px;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 50px;
    transition: background-color .25s ease;

  }
  &__icon-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &_single {
    width: 336px;

    @media (max-width: 343px) {
      width: 279px;
    }

    @media (max-width: 320px) {
      width: 256px;
    }

    #{$this} {

      &__image {
        height: 336px;

        @media (max-width: 343px) {
          height: 279px;
        }

        @media (max-width: 320px) {
          height: 256px;
        }
      }

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #0C1329;
      }

      .item__price {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #0C1329;
      }
    }
  }
}
