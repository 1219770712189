@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.accordion {
  position: relative;
  opacity: 1;
  overflow: hidden;
  transition: opacity .25s ease, border-radius 1s ease .25s;
  $this: &;

  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }

  &__preview {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    border: none;
    color: inherit;
    padding: 0;
    text-align: left;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-size: 16px;
    font-weight: inherit;
    font-family: inherit;
  }
  &__picture {
    position: relative;
    @include size(64px);
    margin-right: 24px;
    flex-shrink: 0;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0.5px 5px rgba(0, 0, 0, 0.1));
  }
  &__image {
    position: relative;
    @include imageObjectFit;
    mask: url('../../../assets/images/mask.png') no-repeat center center / contain;
  }
  &__icon {
    position: absolute;
    bottom: -3px;
    right: -3px;
    @include size(22px);
    fill: #fff;
    padding: 5px;
    border-radius: 50px;
  }
  &__info {
    position: relative;
    overflow: hidden;
  }
  &__title {
    font-weight: 600;
    @include textDots;
  }
  &__text {
    margin-top: 4px;
    font-weight: 400;
    @include textDots;
  }

  &__body {
    position: relative;
    overflow: hidden;
  }
  &__content {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 0;
  }
  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
  &__button-arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(40px);
    cursor: pointer;
    border: 0;
    color: inherit;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    padding: 0;
    opacity: 1;
    transition: opacity 0.25s ease;

    @include on-hover {
      opacity: .5
    }
  }
  &__icon-arrow {
    display: block;
    fill: currentColor;
    transform: rotate(0deg);
  }
  &__icon-expand {
    position: absolute;
    right: 16px;
    fill: currentColor;
    transform: rotate(180deg);
  }

  @include on-hover {
    opacity: 0.8;
  }

  // block type
  &_tweet {
    #{$this} {
      &__icon {
        background: #1DA1F1;
      }
    }
  }
  &_form {
    #{$this} {
      &__content {
        padding: 0;
      }
    }
  }

  // states
  &_isOpen {
    transition: opacity .25s ease, border-radius .25s ease;

    @include on-hover {
      opacity: 1;
    }
    #{$this} {}
  }

  // modifiers
  &_withImage {
    #{$this} {
      &__wrapper {
        justify-content: flex-start;
      }
    }
  }
  &_withExpandIcon {
    #{$this} {
      &__wrapper {
        padding-right: 30px;
      }
    }
  }
}
