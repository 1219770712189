@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.list {
  margin-top: 24px;

  @include MQ(s) {
    margin-top: 20px;
  }
}
