@import "../mixins";
@import "../utils/mixins";

.button-component {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding: 0 8px;
  background: #0C1329;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: inherit;
  font-family: inherit;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  border: none;
  opacity: 1;
  transition: opacity 0.25s ease;
  word-break: break-word;
  will-change: transform;
  text-decoration: none;
  $this: &;

  @include phone {
    padding: 16px 0;
    min-height: 48px;
  }

  &__icon {
    position: absolute;
    left: 8px;
    max-width: 48px;
    max-height: 48px;

    @include phone {
      max-width: 32px;
      max-height: 32px;
    }
  }
  &__icon-svg {
    display: block;
    margin: 0 12px;

    @include phone {
      margin: 0 4px;
    }
  }
  &__text {
    @include phone {
      padding: 0 45px;
    }
  }

  @include on-hover {
    opacity: 0.8;
  }

  &_no-icon {
    #{$this} {
      &__text {
        @include phone {
          padding: 0 8px;
        }
      }
    }
  }
}
