@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/mixins";

.lottie {
  &__loader {
    position: relative;
    top: 0;
    left: 0;
  }

  svg {
    width: 100vw !important;
    height: 100vh !important;
  }
}
