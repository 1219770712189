@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.dropdown {
  position: relative;
  margin-bottom: 20px;
  $this: &;

  &__button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 48px;
    padding: 12px 16px;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: $black;
    background: #fff;
    box-shadow: 0 0 0 1px $stroke;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.25s ease;

    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all .25s ease;
    }

    &:hover {
      box-shadow: 0 0 0 1px $green;
    }
    &:focus {
      box-shadow: 0 0 0 2px $green;
    }
    &:disabled {
      opacity: .4;
      cursor: default;
    }
  }
  &__button-text {
    display: block;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__button-icon {
    flex-shrink: 0;
    margin-left: 16px;
    fill: currentColor;
    transform: translate3d(0,0,0) rotate(180deg);
    transition: transform .25s ease;
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 101%;
    margin-top: 8px;
    padding: 4px;
    background: #FFFFFF;
    border: 1px solid $stroke;
    border-radius: 4px;
    z-index: 1;
    pointer-events: none;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border: 0;
    text-align: left;
    background: transparent;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    border-radius: 4px;
    outline: none;
    transition: background .25s ease;

    &:hover {
      background: $grey_bg;
    }
    &:focus {
      background: $grey_bg;
    }
    &:disabled {
      pointer-events: none;
    }
    &_active {
      background: $grey_bg;
    }
  }
  &__item-text {
    display: block;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__item-icon {
    flex-shrink: 0;
    margin-left: 16px;
  }
  &__divider {
    display: block;
    width: calc(100% - 24px);
    height: 1px;
    background: $stroke;
    border-radius: 50px;
    margin: 4px auto;
    pointer-events: none;
  }

  &_isOpened {
    #{$this} {
      &__button {
        box-shadow: 0 0 0 2px $green;
      }
      &__button-icon {
        transform: translate3d(0,0,0) rotate(0deg);
      }
      &__list {
        pointer-events: auto;
      }
    }
  }
}
