@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.product {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  transform: translateZ(0);
  $this: &;

  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }

  &__body {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    opacity: 1;
    transition: opacity .25s ease;
    background: none !important;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    word-break: break-word;

    //media
    @include MQ(s) {
      margin-bottom: 8px;
    }
    //media

    &_no-price {
      margin-bottom: 0 !important;
    }

  }

  &__description {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
    word-break: break-word;

    //media
    @include MQ(s) {
      margin-bottom: 16px;
    }
    //media

    &_no-price {
      margin-bottom: 0 !important;
    }

  }

  &__price {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
}

.popup {
  &__container {
    max-height: 540px;

    //media
    @include MQ(s) {
      max-height: initial;
    }
    //media

  }
}
