// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/mixins";

.create-bio-site-button {
  position: sticky;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 262px;
  height: 64px;
  margin: 0 auto;
  background: #000000;
  border: 2px solid #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  z-index: 1;

  &_disabled {
    cursor: default;
  }
}

.create-bio-site-button__icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.create-bio-site-button__text {
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
}

.create-bio-site-button__icon-arrow {
  position: relative;
  height: 17px;
  top: 1px;
  left: 3px;
}
