@import "../mixins";

.modal-gallery {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //width: 1024px;
  width: 100vw;
  //height: 788px;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, .45);
  cursor: default !important;

  .inner-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 100px;
      width: 40px;
      height: 40px;
      color: #130f07;
      border-radius: 50px;
      box-shadow: inset 0 0 0 1px #868B8E;
      border: 1px solid #868B8E;
      cursor: pointer;
      z-index: 2;

      @include tablet {
        top: 40px;
      }

      @include phone {
        top: 24px;
        right: 16px;

        @supports (-webkit-touch-callout: none) {
          top: 40px;
        }
      }

      svg {
        path, rect {
          fill: #000000;
        }
      }
    }

    .next,
    .prev {
      top: calc(50% - 20px);
      position: absolute;
      background: white;
      border-radius: 30px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 18px;
      z-index: 2;
      box-shadow: inset 0 0 0 1px #868B8E;
      border: 1px solid #868B8E;

      @include tablet {
        display: none;
      }

      @include phone {
        display: none;
      }
    }

    .next {
      right: 100px;
    }

    .prev {
      left: 100px;
      transform: scale(-1);
    }

    img {
      max-width: 75vw;
      max-height: 90vh;

      @include phone {
        max-width: 100vw;
        max-height: 75vh;
      }
    }

  }
}
