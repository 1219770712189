@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.report-button {
	position: relative;
  display: block;
  font-size: 14px;
  line-height: 18px;
  opacity: .5;
  background: transparent;
  border: 0;
	padding: 0;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .2);

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;

    &:hover {
      opacity: .5;
      text-decoration: underline;
    }
  }
}
