// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";


.progressbar {
  pointer-events: none;
}

.spinner {
  position: fixed;
  top: 25px;
  right: 25px;
  display: block;

  &__body {
    border-bottom: 2px solid transparent;
    border-left: 2px solid $green;
    border-radius: 50%;
    border-right: 2px solid transparent;
    border-top: 2px solid $green;
    height: 30px;
    width: 30px;
  }
}

.bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: $green;
  z-index: 10;

  &__shadow {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
    box-shadow: 0 0 10px $green, 0 0 5px $green;
  }
}
