@import "../../../../../../../assets/scss/utils/variables";
@import "../../../../../../../assets/scss/utils/mixins";

.link {
  flex: 1;
  display: block;
  text-align: center;
  padding: 13px 10px;
  height: 48px;
  min-height: 48px;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  background: none;
  opacity: 1;
  overflow: hidden;
  will-change: transform;
  transition: opacity 0.25s ease;

  @include on-hover {
    opacity: .8;
  }

  &__text {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    -webkit-background-clip: text !important;

    &_dots {
      display: block;
      @include textDots;
    }
  }
  &__text-dummy {
    position: absolute;
    display: inline-block;
    visibility: hidden;
  }
  &__text-original {}
  &__image {}
}
