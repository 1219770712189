.logo-component {
  //padding-top: 40px;
  max-width: 70px;
  max-height: 50px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
}

.default-logo {
  cursor: pointer;

  &_disabled {
    cursor: default;
  }
}
