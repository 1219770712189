@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.work-hours {
  display: block;
  width: 100%;
  margin: 14px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  $this: &;

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    color: inherit;

    @include on-hover {
      #{$this} {
        &__icon {
          transform: translate3d(2px,0,0) rotate(90deg);
        }
      }
    }
  }
  &__icon {
    position: relative;
    top: 0.5px;
    display: block;
    transform: translate3d(0,0,0) rotate(90deg);
    @include size(8px);
    margin-left: 3px;
    transition: transform .25s ease;

    path {
      fill: currentColor;
    }
  }
}

.popup {

  //media
  @include MQ(s) {
    padding: 0;
  }
  //media

  &__container {
    @include size(100%, auto);

    //media
    @include MQ(s) {
      max-width: initial;
    }
    //media

  }
  &__body {
    position: relative;
    margin-top: 8px;
    padding: 0 24px;

    //media
    @include MQ(s) {
      padding: 0 32px;
    }
    //media
  }
}
