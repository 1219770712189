@import "../../../assets/scss/utils/mixins";
@import "../../../assets/scss/utils/variables";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(100%);
  padding: 24px;
  z-index: 5;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  &_mobile {
    padding: 0;
    align-items: flex-end;
  }
}
