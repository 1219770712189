@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.category {
  position: relative;

  &:not(:last-child) {
    padding-bottom: 64px;

    @include MQ(s) {
      padding-bottom: 46px;
    }
  }

  &__title {
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    word-break: break-word;
  }
  &__list {
    margin-top: 24px;
  }
}
