@import "variables";

// responsive / adaptive
@mixin responsive($property, $default_size, $min_size) {
  $size: $default_size - $min_size;
  #{$property}: calc(#{$min_size + px} + #{$size} * ((100vw - 320px) / #{$max_width - 320}));
}

@mixin media($width, $min_max: max) {
  @media (#{$min_max}-width: #{$width}px) {
    @content
  }
}

@mixin MQ($name, $min_max: max) {
  $width: map-get($grid_breakpoints, $name);

  @if ($width) {
    @include media($width, $min_max) {
      @content;
    }
  } @else {
    @include media($name, $min_max) {
      @content;
    }
  }
}

// orientation
@mixin landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait() {
  @media (orientation: portrait) {
    @content;
  }
}


// styles only for the desired device
@mixin ie() {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin moz() {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin edge() {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin safari() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      @content;
    }
  }
}


// controlled hover (hover only for desktop device)
@mixin on-hover {
  @media (hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
      @content;
    }
  }
}

// styles to one line
@mixin textDots() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin imageObjectFit($type: cover) {
  @include size(100%);
  object-fit: $type;
}
@mixin addUnderLine($reverse: 0, $height: 1px, $top: 100%, $bottom: inherit, $pseudo: before) {
  @if ($reverse == 1) {
    &::#{$pseudo} {
      content: '';
      position: absolute;
      top: $top;
      bottom: $bottom;
      left: 0;
      width: 100%;
      height: $height;
      transform: scale3d(1,1,1);
      transform-origin: 0 50%;
      background: currentColor;
      transition: transform .25s ease;
    }
    @include on-hover {
      &::#{$pseudo} {
        transform: scale3d(0,1,1);
        transform-origin: 100% 50%;
      }
    }
    &:focus {
      &::#{$pseudo} {
        transform: scale3d(0,1,1);
        transform-origin: 100% 50%;
      }
    }
  } @else {
    &::#{$pseudo} {
      content: '';
      position: absolute;
      top: $top;
      bottom: $bottom;
      left: 0;
      width: 100%;
      height: $height;
      transform: scale3d(0,1,1);
      transform-origin: 100% 50%;
      background: currentColor;
      transition: transform .25s ease;
    }
    @include on-hover {
      &::#{$pseudo} {
        transform: scale3d(1,1,1);
        transform-origin: 0 50%;
      }
    }
    &:focus {
      &::#{$pseudo} {
        transform: scale3d(1,1,1);
        transform-origin: 0 50%;
      }
    }
  }
}
