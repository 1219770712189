@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  padding-top: 0;
  height: 100%;

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  &__icon {
    font-size: 48px;
    line-height: 48px;
  }
  &__title {
    margin-top: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: $black;
  }

  &__button {
    flex-shrink: 0;
    padding: 16px 24px;
    margin: 24px auto 0;
    width: 100%;
  }

  &_mobile {
    padding-top: 40px;
  }
}

