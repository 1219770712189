@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.popup {

  //media
  @include MQ(s) {
    padding: 0;
  }
  //media

  &__container {
    max-width: 588px;
    max-height: 608px;
    overflow: hidden;

    //media
    @include MQ(s) {
      max-width: initial;
      max-height: initial;
      border-radius: 0;
    }
    //media
  }
  &__body {}
}
