@import "../mixins";

.blocks-list {
  width: 100%;

  .video-component, .tweet-component, .donation-component, .promo-code-component, .form-component-wrapper {
    margin: 0 auto;
  }

  .form-component-wrapper {
    width: 100%;
  }

  .button-component,
  .button-component-no-icon,
  .video-component,
  .gallery-component,
  .tweet-component,
  .donation-component,
  .promo-code-component,
  .form-component-wrapper,
  .form-finish,
  .form-error
  {
    margin-top: 24px;

    @include phone {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 24px;

      @include phone {
        margin-bottom: 16px;
      }
    }
  }

  .radius-0px {
    border-radius: 0 !important;
  }

  .radius-4px {
    border-radius: 4px !important;
  }

  .radius-16px {
    border-radius: 16px !important;
  }
}

.settings {
  position: relative;
  margin-bottom: 50px;
  background: #fff;
  border-radius: 4px;
  padding: 4px;

  &__select {
    display: block;
    width: 100%;
  }
  &__wrapper {
    position: relative;
    display: block;
    margin-top: 16px;
  }
  &__spring-item {
    display: block;
    margin-bottom: 16px;
  }
  &__spring-name {
    display: block;
  }
  &__spring-input {
    display: block;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
  }
}
