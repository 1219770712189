@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding: 0 8px;
  font-size: 16px;
  font-weight: inherit;
  line-height: 20px;
  word-break: break-word;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  overflow: hidden;
  transition: opacity 0.25s ease;
  transform: translateZ(0);
  outline: none;
  $this: &;

  //media
  @include MQ(s) {
    min-height: 48px;
    padding: 16px 45px;
  }
  //media

  &__text {}
  &__icon {
    position: absolute;
    display: block;
    right: 20px;
    fill: currentColor;
    @include size(24px);
    padding: 6px;
    transform: rotate(90deg);

    //media
    @include MQ(s) {
      right: 12px;
      //@include size(32px);
    }
    //media

  }

  @include on-hover {
    opacity: 0.8;
  }
}
