@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.list {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  &__column {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &_bold {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
