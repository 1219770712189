@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.svg-inline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  $this: &;

  &__icon {
    max-width: 100%;
  }

  &_hide {
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;

    #{$this} {
      &__icon {
        opacity: 0;
      }
    }
  }
}
