// utils
@import "../../../assets/scss/utils/variables";
@import "../../../assets/scss/mixins";

.create-bio-site-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  color: #FFFFFF;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.create-bio-site-link__logo {
  height: 40px;
}

.create-bio-site-link__text {
  display: flex;
  align-items: center;
}

.create-bio-site-link__icon-arrow {
  position: relative;
  height: 14px;
  top: 1px;
  left: 3px;
}
