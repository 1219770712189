@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.form {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  $this: &;

  &__fields {
    flex: 1;
  }

  &__text-field {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__dropdown {
    margin-bottom: 28px;
  }
  &__input {
    background: #fff;
  }
  &__textarea {
    background: #fff;
    min-height: 144px;
  }

  &__action {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: -30px;
      left: 50%;
      width: 101%;
      height: 100px;
      transform: translate(-50%, 0);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 25%, #fff 100%);
      pointer-events: none;
      display: none;
    }

    //media
    @media (max-height: 550px) {
      bottom: 5px;

      &::before {
        display: block;
      }
    }
    //media

  }
  &__send-button {
    width: 100%;
    pointer-events: auto;
    padding: 16px 24px;
  }
}
