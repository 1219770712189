@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.container {
  position: relative;
  max-height: 530px;
  max-width: 385px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(13, 13, 13, 0.08);

  &__content {
    position: relative;
    width: 100%;
    height: calc(100% - 64px);
    padding: 0 24px 24px;
    overflow: auto;
  }
}
