@import "../mixins";

.swiper-gallery {

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 588px;
    height: 588px;
    border-radius: 0;
    font-size: 22px;

    @include phone {
      height: calc(100vw - 32px);
    }

    .swiper-slide-shadow {
      background: none;
    }
  }

  .swiper-pagination {
    bottom: -26px;
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 4px;
    border: 2px solid;
    cursor: pointer;
    transition: all 0.15s ease-in;
  }
}
