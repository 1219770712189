@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
  }

  &__image {
    display: block;
    width: 40px;
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &__close {
    @include size(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__close-icon {
    display: block;
  }
}
