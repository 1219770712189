@import "../mixins";

.donation-component {
  position: relative;
  text-align: center;
  margin-top: 24px;
  width: 100%;
  padding: 32px 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__button {
    display: inline-block;
    margin-top: 24px;
    padding: 16px 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    word-break: break-word;
    white-space: pre-wrap;
    will-change: transform;
    transition: opacity 0.25s ease;

    @include phone {
      width: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__button-text {

    &_gradient {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
    }
  }
}

.radius-16px {
  .donation-component__button {
    border-radius: 26px !important;
  }
}

