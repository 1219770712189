.icon-wrapper, .icon-wrapper-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in;
  cursor: pointer;
  outline: none;
}

.icon-wrapper-disabled {
  background: #F2F4FB;
}

.icon-wrapper rect, .icon-wrapper path {
  transition: 0.15s ease-in;
}



