@import "../mixins";
@import "src/assets/scss/utils/mixins";

.video-component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  overflow: hidden;
  $this: &;

  &__player {
    @include size(100%);
    padding-bottom: 56%;

    & > div {
      position: absolute;
      @include size(100%);
    }
  }
  &__tiktok-player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 auto;

    @include MQ(345) {
      width: 100vw;
      margin-left: -10px;
    }
  }

  &_facebook {
    #{$this} {
      &__player {
        padding-bottom: 100%;
      }
    }
  }
  &_tiktok {
    width: 330px;
    height: 741px;
    pointer-events: all;

    @include MQ(345) {
      width: 100%;
    }
  }
}
