@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.item {
  flex-shrink: 0;
  position: relative;
  display: block;
  padding: 12px 10px;
  border-radius: 6px;
  cursor: pointer;
  border: 0;
  color: $black;
  background: transparent;
  box-shadow: inset 0 0 0 1px $stroke;
  transition: box-shadow 0.5s ease;
  $this: &;

  &:first-child {
    margin-left: 40px; // 56 - 16 == 48 / 56 - body padding / 16 - list padding

    //media
    @include MQ(s) {
      margin-left: 16px; // 32 - 16 == 24 / 32 - body padding / 16 - list padding
    }
    //media

  }
  &:not(:last-child) {
    margin-right: 8px;
  }

  &__text {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  &__text-bold {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(100%);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
  }

	&__text-hidden {
    @include size(100%);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
		opacity: 0;
		display: block;
		height: 0;
		pointer-events: none;
		z-index: -1;
	}

  &__decor {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    border-radius: 6px;
    box-shadow: inset 0 0 0 2px $black;
    z-index: 1;
  }

  @include on-hover {
    box-shadow: inset 0 0 0 1px $black;
  }

  &_active {
    @include on-hover {
      box-shadow: inset 0 0 0 1px $stroke;
      transition: box-shadow 0.25s ease;
    }
  }
}
