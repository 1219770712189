@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/mixins";

.background {
  position: fixed;
  left: 0;
  top: 0;
  @include size(100%);
  display: grid;
  place-items: center;
  z-index: 0;

  &_backgroundSizeAuto100 {
    background-size: auto 100% !important;
  }
  &_backgroundPositionTop {
    background-position: top !important;
  }
}
