@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  background: #fff;
  z-index: 1;
}

.header {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  min-height: 56px;
  background: #fff;
  z-index: 2;

  &__button-back {
    position: absolute;
    top: 16px;
    left: 16px;
    @include size(40px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include size(24px);
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
  &__button-icon {
    display: block;
  }
}

.item {
  flex: 1;
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0 56px 0;

  //media
  @include MQ(s) {
    padding: 0 32px 0;
  }
  //media

  &__picture {
    position: relative;
    display: block;
		width: 100%;
    min-height: 280px;
    max-height: 280px;
    max-width: 280px;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 16px;
    z-index: 1;
    pointer-events: none;

    //media
		@include MQ(s) {
			max-height: 390px;
			max-width: 390px;
    }
    @include MQ(xs) {
      max-width: unset;
      max-height: unset;
    }
    //media

  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    @include size(100%);
  }

  &__info {
    flex-shrink: 0;
    display: block;
    position: relative;
    margin-top: 24px;
    padding-bottom: 56px;
    word-break: break-word;
    overflow: hidden;

    //media
    @include MQ(s) {
      padding-bottom: 32px;
    }
    //media
  }
  &__title {
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  &__description {
    display: block;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #848994;
  }
  &__price {
    display: block;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
}

.diet {
  margin-top: 24px;

  &__item {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &__icon {
    @include size(24px);
  }
}
