@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.item {
  --image-width: 82px;
  --info-margin-right: 24px;

  //media
  @include MQ(s) {
    --image-width: 64px;
  }
  //media

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
  -webkit-appearance: none;
  outline: none;
  $this: &;

  &:not(:last-child) {
    margin-bottom: 32px;

		@include MQ(s) {
			margin-bottom: 24px;
		}
  }

  &__info {
    flex: 1;
    width: calc(100% - var(--image-width) - var(--info-margin-right));
    margin-right: var(--info-margin-right);
    display: flex;
    flex-flow: column;
  }
  &__wrapper {
    flex: 1;
  }

  &__picture {
    position: relative;
    flex: 0 0 var(--image-width);
    border-radius: 8px;
    overflow: hidden;
    transform: translateZ(0);
    pointer-events: none;
    background: #eee;
  }
  &__image {
    display: block;
    @include size(100%);
    object-fit: cover;
  }

  &__title {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: $black;

    //media
    @include MQ(s) {
      max-width: 100%;
      word-wrap: break-word;
    }
    //media
  }
  &__price {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $black;
  }
  &__description {
    display: block;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #848994;
  }
  &__diet {
    flex-shrink: 0;
    margin-top: 6px;
  }
  &__diet-item {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  &_no-image {
    --info-margin-right: 0;

    #{$this} {
      &__info {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
