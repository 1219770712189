@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  z-index: 999999;

  //media
  @include MQ(s) {
    align-items: flex-end;
  }
  //media


  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    padding: 0;
    cursor: pointer;
  }
}
