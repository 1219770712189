@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.item {
  display: block;
  text-align: center;
  width: 100%;
  min-height: 56px;
  padding: 15px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  color: inherit;
  @include textDots;
  text-decoration: none;
  border: 1px solid $grey_icon_hover;
  border-radius: 8px;
  cursor: pointer;
  opacity: 1;
  transition: opacity .25s ease;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include on-hover {
    opacity: .6
  }

  &__image {
    max-width: 100%;
  }
  &__text {}

  &_with-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 2px;
  }
}
