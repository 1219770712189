@import "../mixins";

.buttons-list {
  width: 100%;

  .button-component, .button-component-no-icon {
    margin-top: 24px;

    @include phone {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 24px;

      @include phone {
        margin-bottom: 16px;
      }
    }
  }
}
