// weight name
$fontWeightLight: 'Light';
$fontWeightRegular: 'Regular';
$fontWeightMedium: 'Medium';
$fontWeightSemiBold: 'SemiBold';
$fontWeightBold: 'Bold';

// font name
$alegreyaFont: 'Alegreya';
$courierFont: 'Courier';
$GaramondFont: 'Garamond';
$interFont: 'Inter';
$loraFont: 'Lora';
$merriweatherFont: 'Merriweather';
$notoSerifFont: 'NotoSerif';
$oswaldFont: 'Oswald';
$poppinsFont: 'Poppins';
$robotoFont: 'Roboto';
$rubikFont: 'Rubik';
$spectralFont: 'Spectral';
$tinosFont: 'Tinos';
$yanoneFont: 'Yanone';
$notoSansFont: 'NotoSans';

// font map
$fontsStyles: (
  $alegreyaFont 400 '#{$alegreyaFont}-#{$fontWeightLight}',
  $alegreyaFont 600 '#{$alegreyaFont}-#{$fontWeightRegular}',
  $alegreyaFont 700 '#{$alegreyaFont}-#{$fontWeightMedium}',
  $courierFont 400 '#{$courierFont}-#{$fontWeightRegular}',
  $courierFont 700 '#{$courierFont}-#{$fontWeightBold}',
  $GaramondFont 400 '#{$GaramondFont}-#{$fontWeightRegular}',
  $GaramondFont 600 '#{$GaramondFont}-#{$fontWeightSemiBold}',
  $GaramondFont 700 '#{$GaramondFont}-#{$fontWeightBold}',
  $interFont 400 '#{$interFont}-#{$fontWeightRegular}',
  $interFont 600 '#{$interFont}-#{$fontWeightSemiBold}',
  $interFont 700 '#{$interFont}-#{$fontWeightBold}',
  $loraFont 400 '#{$loraFont}-#{$fontWeightRegular}',
  $loraFont 600 '#{$loraFont}-#{$fontWeightSemiBold}',
  $loraFont 700 '#{$loraFont}-#{$fontWeightBold}',
  $merriweatherFont 400 '#{$merriweatherFont}-#{$fontWeightRegular}',
  $merriweatherFont 700 '#{$merriweatherFont}-#{$fontWeightBold}',
  $notoSerifFont 400 '#{$notoSerifFont}-#{$fontWeightRegular}',
  $notoSerifFont 700 '#{$notoSerifFont}-#{$fontWeightBold}',
  $oswaldFont 400 '#{$oswaldFont}-#{$fontWeightRegular}',
  $oswaldFont 600 '#{$oswaldFont}-#{$fontWeightSemiBold}',
  $oswaldFont 700 '#{$oswaldFont}-#{$fontWeightBold}',
  $poppinsFont 400 '#{$poppinsFont}-#{$fontWeightRegular}',
  $poppinsFont 600 '#{$poppinsFont}-#{$fontWeightSemiBold}',
  $poppinsFont 700 '#{$poppinsFont}-#{$fontWeightBold}',
  $robotoFont 400 '#{$robotoFont}-#{$fontWeightRegular}',
  $robotoFont 700 '#{$robotoFont}-#{$fontWeightBold}',
  $rubikFont 400 '#{$rubikFont}-#{$fontWeightRegular}',
  $rubikFont 600 '#{$rubikFont}-#{$fontWeightSemiBold}',
  $rubikFont 700 '#{$rubikFont}-#{$fontWeightBold}',
  $spectralFont 400 '#{$spectralFont}-#{$fontWeightRegular}',
  $spectralFont 600 '#{$spectralFont}-#{$fontWeightSemiBold}',
  $spectralFont 700 '#{$spectralFont}-#{$fontWeightBold}',
  $tinosFont 400 '#{$tinosFont}-#{$fontWeightRegular}',
  $tinosFont 700 '#{$tinosFont}-#{$fontWeightBold}',
  $yanoneFont 400 '#{$yanoneFont}-#{$fontWeightRegular}',
  $yanoneFont 600 '#{$yanoneFont}-#{$fontWeightSemiBold}',
  $yanoneFont 700 '#{$yanoneFont}-#{$fontWeightBold}',
  $notoSansFont 400 '#{$notoSansFont}-#{$fontWeightRegular}',
  $notoSansFont 700 '#{$notoSansFont}-#{$fontWeightBold}',
);

// add font-face
@each $name, $weight, $pathName in $fontsStyles {
  @font-face {
    font-family: $name;
    font-weight: $weight;
    src: local($name),
    url('../fonts/#{$name}/#{$pathName}.woff2') format('woff2'),
    url('../fonts/#{$name}/#{$pathName}.woff') format('woff');
  }
}
