// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/mixins";

.promo-code-component {
  position: relative;
  padding: 24px;
  mask-image: url('/assets/svg/promoMasks/maskDesktop-0px.svg');
  mask-size: initial;
  -webkit-mask-position-y: -1px;
  mask-position-y: -1px;
  border: none !important;

  @include phone {
    mask-size: cover;
    mask-image: url('/assets/svg/promoMasks/mask-0px.svg');
  }

  @media (max-width: 428px) {
    -webkit-mask-position-y: -32px;
    mask-position-y: -32px;
  }

  @media (max-width: 414px) {
    -webkit-mask-position-y: -26px;
    mask-position-y: -26px;
  }

  @media (max-width: 393px) {
    -webkit-mask-position-y: -18px;
    mask-position-y: -18px;
  }

  @media (max-width: 390px) {
    -webkit-mask-position-y: -17px;
    mask-position-y: -17px;
  }

  @media (max-width: 375px) {
    -webkit-mask-position-y: -10px;
    mask-position-y: -10px;
  }

  @media (max-width: 320px) {
    mask-size: contain;
    -webkit-mask-position-y: 10px;
    mask-position-y: 10px;
  }

  @media (max-width: 312px) {
    -webkit-mask-position-y: 29px;
    mask-position-y: 29px;
  }
}

.promo-code-component-no-data {
  -webkit-mask-position-y: -47px;
  mask-position-y: -47px;

  @media (max-width: 428px) {
    -webkit-mask-position-y: -76px;
    mask-position-y: -76px;
  }

  @media (max-width: 414px) {
    -webkit-mask-position-y: -74px;
    mask-position-y: -74px;
  }

  @media (max-width: 393px) {
    -webkit-mask-position-y: -66px;
    mask-position-y: -66px;
  }

  @media (max-width: 390px) {
    -webkit-mask-position-y: -66.5px;
    mask-position-y: -66.5px;
  }

  @media (max-width: 375px) {
    -webkit-mask-position-y: -61px;
    mask-position-y: -61px;
  }

  @media (max-width: 320px) {
    mask-size: cover;
    -webkit-mask-position-y: -31px;
    mask-position-y: -31px;
  }
}

.promo-code-component-preview-mode {
  @media (max-width: 375px) {
    mask-size: contain;
    -webkit-mask-position-y: -3px;
    mask-position-y: -3px;
  }
}

.promo-code-component-no-data-preview-mode {
  @media (max-width: 375px) {
    mask-size: cover;
    -webkit-mask-position-y: -5px;
    mask-position-y: -5px;
  }
}

.promo-code-component__top {
  display: flex;
  align-items: center;
}

.promo-code-component__top_image {
  width: 80px;
  height: 80px;
  margin-right: 16px;
  border-radius: 4px;
  z-index: 1;
}

.promo-code-component__top_right {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promo-code-component__top_right-discount {
  max-width: 58px;
  max-width: 58px;
  margin-bottom: 8px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  will-change: transform;
  z-index: 1;
}

.promo-code-component__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  word-break: break-word;
  white-space: pre-wrap;
  z-index: 1;

  @include phone {
    margin-top: 6px;
  }
}

.promo-code-component__title-small {
  @include phone {
    font-size: 14px;
    line-height: 16px;
  }
}

.promo-code-component__title-small-preview {
  @include phone {
    font-size: 11px;
  }
}

.promo-code-component__description {
  margin-top: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.5;
}

.promo-code-component__field {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  background-size: 0 !important;

  @include phone {
    margin-top: 8px;
  }
}
.promo-code-component__field-icon {
  position: absolute;
  top: 50%;
  right: 16px;
}

.promo-code-component__button {
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
}

.promo-code-component__button_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.promo-code-component__button {
  display: inline-block;
  margin-top: 16px;
  padding: 16px 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  word-break: break-word;
  white-space: pre-wrap;
  will-change: transform;
  transition: opacity 0.25s ease;

  @include phone {
    width: 100%;
    margin-top: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.promo-code-component__top_right-discount_text-gradient, .promo-code-component__button_text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div {
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    //overflow: overlay;
    overflow: initial;
  }

  path {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    stroke-width: 1;
  }
}

