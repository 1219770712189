// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #0C1329;
  z-index: 2;
  flex-shrink: 0;

  &__logo {
    display: block;
    width: 35px;
  }

  &__logo-img {
    max-width: 100%;
  }
}
