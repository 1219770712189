@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/animations";

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 16px;
  border: 0;
  font-weight: 700;
  font-family: 'Noto-Sans-Bold', sans-serif;
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
  color: #fff;
  background: $green;
  border-radius: 6px;
  overflow: hidden;
  opacity: 1;
  transition: background 0.25s ease, color 0.25s ease, opacity 0.25s ease;
  $this: &;

  &__text {
    opacity: 1;
    font-family: inherit;
    transition: opacity 0.25s ease;
  }
  &__icon {
    opacity: 1;
    @include size(16px);
    margin-right: 8px;
    transition: opacity 0.25s ease;

    path {
      fill: #fff;
      transition: fill 0.25s ease;
    }
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;
    opacity: 0;
    transition: opacity 0.25s ease;
    pointer-events: none;
  }
  &__loading-icon {
    animation: spinning 3s linear infinite;
  }

  &:hover {
    background: $green_hover;
  }
  &:active {
    background: $green_active;
  }
  &:disabled {
    background: $green_disabled;
    cursor: default;

    #{$this} {
      &__loading {
        background: $green_disabled;
      }
    }

    &:hover {
      background: $green_disabled;
    }
  }

  &_small {
    padding: 8px 16px;
    font-weight: 600;
  }

  &_loading {
    #{$this} {
      &__loading {
        opacity: 1;
      }
    }
  }
  &_secondary {
    background: transparent;
    color: $grey_icon_hover;
    box-shadow: inset 0 0 0 1px $grey_icon_hover;

    #{$this} {
      &__icon {
        path {
          fill: $grey_icon_hover;
        }
      }
    }

    &:hover, &:active {
      background: transparent;
      color: $grey_text;
      box-shadow: inset 0 0 0 1px $grey_text;

      #{$this} {
        &__icon {
          path {
            fill: $grey_text;
          }
        }
      }
    }
    &:disabled {
      &:hover, &:active {
        background: transparent;
        color: $grey_icon_hover;
        box-shadow: inset 0 0 0 1px $grey_icon_hover;

        #{$this} {
          &__icon {
            path {
              fill: $grey_icon_hover;
            }
          }
        }
      }
    }
  }
}
