@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/mixins";

.body {
  position: relative;
  padding: 0 24px;

  @include MQ(s) {
    padding: 0 32px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 16px;

    @include MQ(s) {
      margin-top: 8px;
    }

    @media (max-height: 550px) and (orientation: landscape) {
      margin-top: 24px;
    }

    &_single {
      justify-content: center;
    }
  }
}
