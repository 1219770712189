@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";


.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 0;
  margin-bottom: 24px;
  margin-top: 16px;
  opacity: 0;
  transition: 0.25s ease;

  &__title {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    min-height: 24px;
  }
  &__close {
    position: absolute;
    top: 50%;
    right: 24px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    transform: translateY(-50%);
    @include size(14px);

    img {
      max-width: 100%;
    }
  }

  &_show {
    opacity: 1;
  }
}
