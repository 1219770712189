@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.diet {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-start;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  &__icon {
    display: block;
    @include size(16px);

    &_with-title {
      margin-right: 2px;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
  }
}
