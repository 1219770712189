@import "src/assets/scss/utils/mixins";
@import "src/assets/scss/utils/variables";

.google {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;

  &__rating {
    margin-top: 10px;
  }
  &__address {
    margin-top: 10px;
  }
}
.rating {
  display: flex;
  align-items: center;

  &__number {
    margin-right: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
  &__icon {
    margin-left: 6px
  }
}
.address {
  display: flex;
  align-items: center;
  max-width: 588px;

  //media
  @include MQ(588) {
    max-width: calc(100vw - 32px);
  }
  //media


  &__icon {
    flex-shrink: 0;
    display: block;
    margin-right: 6px;

    path {
      fill: currentColor;
    }
  }
  &__link {
    flex: 1 1 auto;
    display: inline-block;
    color: inherit;
    @include textDots;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;

    @include on-hover {
      text-decoration: none;
    }
  }
}
