@use "sass:math";

// Variables
// ----------------------------------------------

// default values
$max_width: 1280;

// grid settings
$grid_breakpoints: (
  xl: 1920,
  l: 1280,
  m: 1024,
  s: 679,
  xs: 375,
  xxs: 320,
);

// colors (color names by figma)
$green: #40BCA3;
$green_hover: #45CBB0;
$green_hover: #40BCA3;
$green_active: #38A58F;
$green_disabled: #a7ded1;
$black: #0C1329;
$grey_icon_hover: #98A0BC;
$stroke: #DCE2F5;
$grey_bg: #F2F4FB;
$red: #BC4040;
$grey_text: #72788D;
