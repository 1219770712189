@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.input {
  position: relative;
  display: block;
  text-align: left;

  &__label {
    margin-bottom: 8px;
  }
  &__label-text {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    &_optional {
      font-weight: 400;
      color: $grey_icon_hover;
    }
  }
  &__field {
    position: relative;
    width: 100%;
    max-height: 48px;
    margin-bottom: 20px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    background: $grey_bg;
    box-shadow: inset 0 0 0 1px $stroke;
    border-radius: 4px;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 0;
    -webkit-appearance: none;
    transition: box-shadow 0.25s ease;

    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all .25s ease;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $green;
    }
    &:focus {
      box-shadow: inset 0 0 0 2px $green;
      text-overflow: unset;

      &::-webkit-input-placeholder {
        opacity: 0;
        //transition-delay: .35s;
      }

    }
    &:disabled {
      opacity: .4;
      cursor: default;
    }

    &_textarea {
      min-height: 96px;
      text-overflow: unset;
      white-space: initial;
      resize: none;
      overflow: initial;
    }
    &_error {
      box-shadow: inset 0 0 0 1px $red;

      &:hover {
        box-shadow: inset 0 0 0 1px $red;
      }
      &:focus {
        box-shadow: inset 0 0 0 2px $red;
      }
    }
  }
  &__error {
    position: absolute;
    bottom: 3px;
    left: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $red;
  }
  &__max-length {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $grey_icon_hover;
  }

  &_percent {
    &::after {
      content: '%';
      position: absolute;
      top: 50%;
      right: 16px;
      font-size: 16px;
      line-height: 24px;
      transform: translateY(-50%);
      color: $black;
    }
  }
}
