@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.list {
  display: flex;
  flex-shrink: 0;
  padding: 8px 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
