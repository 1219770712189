@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  //media
  @include MQ(s) {
    grid-gap: 8px;
  }
  //media

  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }
}
