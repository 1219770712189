// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/mixins";

.share {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 3;
}

.share__btn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(255,255,255,0.3);
  border-radius: 50%;
  cursor: pointer;
  border: none;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  @include phone {
    display: none;
  }
}

.animation-wrapper {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
