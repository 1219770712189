@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.carousel {
  position: relative;

  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }

  &__swiper {
    overflow: inherit;
  }

  &__item {
    @include size(auto);
  }
}
