// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.share-menu {
  position: fixed;
  top: 175px;
  left: 50%;
  transform: translateX(-50%);
  width: 384px;
  height: 501px;
  background: #FFFFFF;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(174, 178, 194, 0.5);
  border-radius: 8px;

  &:last-child {
    padding: 16px 24px;
  }
}

.share-menu__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #0C1329;
}

.share-menu__item {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid #DCE2F5;

  &:last-child {
    height: 56px;
    padding: 16px 0px 0px;
    border-bottom: none;
  }

  &:hover {
    .icon-wrapper {
      path, react {
        fill: #0C1329;
      }
      rect {
        fill: #0C1329;
        stroke: #0C1329;
      }
      path {
        &:first-child {
          stroke: #0C1329;
        }
      }
    }
  }
}

.share-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 16px 0;
}

.text {
  margin-left: 8px;
  font-family: 'Noto-Sans', sans-serif;
  font-weight: 600;
  color: #0C1329;
  font-size: 14px;
  line-height: 16px;
}

.icon-wrapper {
  margin-left: auto;

  path, react {
    transition: all 0.15s ease-in;
  }
}

.share-menu__item_btn {
  display: flex;
  align-items: center;
}

.share-menu__item_copy-btn {
  width: 66px;
  height: 32px;
  margin-left: auto;
  border: 1px solid #98A0BC;
  border-radius: 6px;
  background-color: #FFFFFF;
  color: #98A0BC;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.15s ease-in;

  &:hover {
    color: #72788D;
    border-color: #72788D;
  }
}

.share-menu__cross-btn {
  position: absolute;
  top: 21px;
  right: 12px;
  border: none;
  background: none;
  cursor: pointer;
}
