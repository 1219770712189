@import "../mixins";

.gallery-component {
  height: 607px;

  @include phone {
    height: calc(100vw - 7px);
  }
}

.radius-0px {
  border-radius: 0px;

  .swiper-gallery {

    .swiper-cards {

      img,.swipe-gallery-item {
        border-radius: 0px;
      }
    }
  }
}

.radius-4px {
  border-radius: 4px;

  .swiper-gallery {

    .swiper-cards {

      img,.swipe-gallery-item {
        border-radius: 4px;
      }
    }
  }
}

.radius-16px {
  border-radius: 16px;

  .swiper-gallery {

    .swiper-cards {

      img,.swipe-gallery-item {
        border-radius: 16px;
      }
    }
  }
}
