@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.product {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  transform: translateZ(0);
  $this: &;

  &:not(:first-child) {
    margin-top: 24px;

    //media
    @include MQ(s) {
      margin-top: 16px;
    }
    //media

  }

  &__header {
    position: relative;

    //media
    @include MQ(xs) {}
    //media

    &_blur {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  &__image {
    display: block;
    @include imageObjectFit();
  }
  &__icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    @include size(40px);
    padding: 12px;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 50px;
    transition: background-color .25s ease;

    //media
    @include MQ(s) {
      bottom: 8px;
      right: 8px;
    }
    //media

  }
  &__icon-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &__body {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    padding: 24px;
    margin-top: -1px;
    opacity: 1;
    transition: opacity .25s ease;

    //media
    @include MQ(s) {
      padding: 16px 24px 24px;
    }
    //media

  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    word-break: break-word;

    //media
    @include MQ(s) {
      margin-bottom: 8px;
    }
    //media

    &_no-price {
      margin-bottom: 0 !important;
    }

  }
  &__description {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
    word-break: break-word;

    //media
    @include MQ(s) {
      margin-bottom: 16px;
    }
    //media

    &_no-price {
      margin-bottom: 0 !important;
    }

  }
  &__price {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  &__price-currency {}
  &__price-number {}
}
