@mixin largePhone {
  @media (max-width: 679px) and (min-width: 414px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 679px) {
    @content;
  }
}

@mixin smallPhone {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin tabletAndDesktop {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1279px) {
    @content;
  }
}

@mixin desktopLarge {
  @media (min-width: 1280px) {
    @content;
  }
}
