@import "../mixins";

.text-component {
  position: relative;
  color: #0C1329;

  &__content {
    word-break: break-word;
    white-space: pre-wrap;
  }

  &_align-left {
    text-align: left;
  }
  &_align-center {
    text-align: center;
  }
  &_align-right {
    text-align: right;
  }

  &_format-h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

    &:not(:first-child) {
      margin-top: 48px;

      @include phone {
        margin-top: 40px;
      }
    }
  }
  &_format-h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:not(:first-child) {
      margin-top: 24px;

      @include phone {
        margin-top: 16px;
      }
    }
  }
}
