@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.popup {

  //media
  @include MQ(s) {
    padding: 40px 0 0;
  }
  //media

  &__wrapper {
    position: relative;
    @include size(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &__container {
    position: relative;
    max-width: 384px;
    max-height: 384px;
    @include size(100%);
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(13, 13, 13, 0.08);
    border-radius: 8px;
    overflow: auto;
    pointer-events: auto;

    //media
    @include MQ(s) {
      max-width: initial;
      max-height: initial;
      border-radius: 8px 8px 0 0;
    }
    //media

  }
}
