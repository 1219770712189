// utils
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/mixins";

.notFound-page {
  content: 'Page';
}

.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
}

.notFound {
  position: relative;
  flex: 1;
  height: 100%;
  z-index: 1;

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1110px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  &__decor-svg {
    position: absolute;

    &_top {
      top: 5%;
      right: 5%;
      width: 110px;
      opacity: 0.2;
      filter: blur(7px);

      //media
      @include MQ(600) {
        opacity: .1;
      }
      //media

    }

    &_bottom {
      bottom: 5%;
      left: 5%;
      width: 183px;
      opacity: 0.2;
      filter: blur(5px);

      //media
      @include MQ(600) {
        opacity: .1;
      }
      //media

    }
  }

  &__info {
    position: relative;
    text-align: center;
    padding: 50px 25px;
  }

  &__title {
    height: auto;
    @include responsive(width, 385, 200);
  }

  &__subtitle {
    content: 'subtitle';
    margin-top: 40px;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 32px;
    color: #0C1329;

    //media
    @include MQ(800) {
      margin-top: 32px;
    }
    @include MQ(500) {
      margin-top: 25px;
    }
    //media

  }

  &__description {
    content: 'description';
    text-align: center;
    margin: 8px auto 0;
    font-size: 16px;
    line-height: 24px;
    color: #72788D;

    //media
    @include MQ(350) {
      max-width: 280px;
    }
    //media

  }

  &__action {
    margin-top: 24px;
  }
}
